import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { differenceInMinutes } from 'date-fns'

import ResultItem from 'containers/searchPanel/results/ResultItem'
import ResultList from 'containers/searchPanel/results/ResultList'
import FlexSpacer from 'components/FlexSpacer'
import ResultBar from 'containers/searchPanel/results/ResultBar'
import { getActiveSpaceId } from 'containers/quickView/selectors'
import ResultHeader from 'containers/searchPanel/results/ResultHeader'
import {
  getSpaceResults,
  getUserResults,
} from 'containers/searchPanel/search/selectors'
import { getActiveByIndex } from 'containers/searchPanel/results/selectors'
import { setActiveByIndex } from 'containers/searchPanel/results/actions'
import {
  getSpaces,
  getActiveDeskIds,
  getActiveRoomIds,
  getMetaTypes,
  getFutureDesks,
  getCurrentFloorId,
  getFutureRooms,
} from 'containers/app/selectors'
import {
  getPropFilters,
  getActiveDataset,
  getBookableFilter,
  getDurationFilter,
  getAvailabilityFilter,
  getNumSeatsFilter,
  getDateFilter,
  getIsFuture,
} from 'containers/searchPanel/filter/selectors'
import { sortByString } from 'utils/utilsFunctions'

class SpaceResults extends Component {
  shouldComponentUpdate(nextProps) {
    const { roomIds, deskIds } = nextProps
    return !(isEmpty(roomIds) || isEmpty(deskIds))
  }

  getFilteredIds = () => {
    const {
      roomIds,
      deskIds,
      activeDataSet,
      isFuture,
      futureDesks,
      futureRooms,
    } = this.props
    const ids = []
    if (isFuture) {
      if (activeDataSet === 'rooms' && futureRooms) {
        futureRooms.map((room) => ids.push(room.id))
        return ids
      }
      if (activeDataSet === 'desks' && futureDesks) {
        futureDesks.map((desk) => ids.push(desk.id))
        return ids
      } else if ((futureDesks || futureDesks) && !activeDataSet) {
        const futureSpaces = [...futureDesks, ...futureRooms]
        futureSpaces.map((asset) => ids.push(asset.id))
      }
      return ids
    }
    if ((activeDataSet === 'rooms' || !activeDataSet) && roomIds) {
      ids.push(...roomIds)
    }
    if ((activeDataSet === 'desks' || !activeDataSet) && deskIds) {
      ids.push(...deskIds)
    }
    return ids
  }

  applyBookableFilter = (arr) => {
    const { bookableFilter, spaces, spaceMetaTypes } = this.props
    if (!bookableFilter) return arr

    return arr.filter((id) => {
      const space = spaces.get(`${id}`)
      if (!space) return false
      const spaceTypeId = space.get('typeId')
      const type = spaceMetaTypes.get(`${spaceTypeId}`)
      return type.getIn(['space', 'mode']) === 'BOOK'
    })
  }

  applyPropFilter = (arr) => {
    const { propFilters, spaces, currentFloorId } = this.props
    const filters = propFilters.filter((filter) => filter)

    return arr.filter((id) => {
      const space = spaces.get(`${id}`)
      if (!space) return false

      const spaceFloorId = space.get('floorId')
      if (filters.size < 1 && spaceFloorId !== currentFloorId) return false

      return filters.keySeq().reduce((acc, filter) => {
        const spaceProps = space.getIn(['info', 'props'])
        if (!spaceProps) return null
        const result = spaceProps.find((prop) => prop.get('name') === filter)
        return !result ? false : acc
      }, true)
    })
  }

  applyNumSeatsFilter = (arr) => {
    const { isFuture, numSeatFilter, spaces } = this.props
    if (numSeatFilter && numSeatFilter > 0) {
      return arr.filter((id) => {
        const space = spaces.get(`${id}`)
        if (!space) return false
        return space.getIn(['info', 'cap']) >= numSeatFilter
      })
    } else {
      return arr
    }
  }

  applyDurationFilter = (arr) => {
    const { isFuture, durationFilter, spaces } = this.props
    if (!isFuture && durationFilter && durationFilter > 0) {
      return arr.filter((id) => {
        const space = spaces.get(`${id}`)
        if (!space) return false
        //filter out meetings that have already finished
        const futureMeetings = space
          .get('meetings')
          .valueSeq()
          .filter((meeting) => {
            return Date.now() < meeting.get('end')
          })
        const firstMeeting = futureMeetings.first()

        //check if there is enough room to schedule the desired meeting duration
        if (firstMeeting) {
          const diff = differenceInMinutes(
            firstMeeting.get('start'),
            Date.now(),
          )
          if (diff < 0) return false // check for ongoing meeting (will give negative diff value)
          return durationFilter < diff //check if there is enough time for the searched duration before the next meeting
        }
        return true
      })
    } else {
      return arr
    }
  }

  applyAvailabilityFilter = (arr) => {
    const {
      futureDesks,
      futureRooms,
      availabilityFilter,
      spaces,
      spaceMetaTypes,
      durationFilter,
      isFuture,
    } = this.props
    if (!availabilityFilter && durationFilter <= 0) return arr

    return arr.filter((id) => {
      const space = spaces.get(`${id}`)
      if (!space) return false
      const spaceTypeId = space.get('typeId')
      const type = spaceMetaTypes.get(`${spaceTypeId}`)

      return isFuture
        ? [...futureDesks, ...futureRooms]?.find((space) => space.id === id)
            ?.state === 'FREE'
        : space.getIn(['status', 'state']) === 'FREE' &&
            (type.getIn(['space', 'mode']) === 'BOOK' ||
              type.getIn(['space', 'mode']) === 'CLAIM' ||
              type.getIn(['space', 'mode']) === 'TAKE')
    })
  }

  sortIntoFloors = (arr) => {
    const { spaces } = this.props
    const splitIntoFloors = {}
    arr.forEach((id) => {
      const space = spaces.get(`${id}`)
      if (!space) return
      const floorId = space.get('floorId')
      splitIntoFloors[floorId]
        ? splitIntoFloors[floorId].push(id)
        : (splitIntoFloors[floorId] = [id])
    })
    return splitIntoFloors
  }

  seperateIntoZones = (arr) => {
    const { spaces } = this.props
    const splitIntoZones = {}
    arr.forEach((id) => {
      const space = spaces.getIn([`${id}`])
      if (!space) return

      const zoneId = space.getIn(['loc', 'spaceId'])
      splitIntoZones[zoneId]
        ? splitIntoZones[zoneId].push(id)
        : (splitIntoZones[zoneId] = [id])
    })
    return splitIntoZones
  }

  getAssetName = (asset) => (asset ? asset.getIn(['info', 'name']) : '')

  sortSpaces = (spaceAId, spaceBId) => {
    const { spaces } = this.props

    const spaceA = spaces.getIn([`${spaceAId}`])
    const spaceB = spaces.getIn([`${spaceBId}`])

    if (!spaceA || !spaceB) return 1
    return sortByString(this.getAssetName(spaceA), this.getAssetName(spaceB))
  }

  render() {
    const { activeSpaceId } = this.props
    const ids = this.getFilteredIds()
    const bookableIds = this.applyBookableFilter(ids)
    const availableIds = this.applyAvailabilityFilter(bookableIds)
    const spaceIdsWithEnoughCapacity = this.applyNumSeatsFilter(availableIds)
    const spaceIdsWithEnoughDuration = this.applyDurationFilter(
      spaceIdsWithEnoughCapacity,
    )
    const filteredIds = this.applyPropFilter(spaceIdsWithEnoughDuration)
    const spacesInZones = this.seperateIntoZones(filteredIds)
    return (
      <Fragment>
        <ResultList>
          {spacesInZones && Object.keys(spacesInZones).length > 1
            ? Object.entries(spacesInZones).map((zoneMap) => (
                <ResultHeader key={zoneMap[0]} id={zoneMap[0]}>
                  {zoneMap[1].sort(this.sortSpaces).map((id) => (
                    <ResultItem
                      key={id}
                      id={id}
                      active={id === activeSpaceId}
                    />
                  ))}
                </ResultHeader>
              ))
            : filteredIds &&
              filteredIds
                .sort(this.sortSpaces)
                .map((id) => (
                  <ResultItem key={id} id={id} active={id === activeSpaceId} />
                ))}
        </ResultList>
        <FlexSpacer />
        <ResultBar>
          <span>
            <Translate id="billboard.searchPanel.results" />
          </span>
          <span />
          <span>{filteredIds.length}</span>
        </ResultBar>
      </Fragment>
    )
  }
}

SpaceResults.propTypes = {
  activeDataSet: PropTypes.string,
  activeSpaceId: PropTypes.number,
  availabilityFilter: PropTypes.bool,
  bookableFilter: PropTypes.bool,
  deskIds: PropTypes.object,
  durationFilter: PropTypes.number,
  numSeatFilter: PropTypes.number,
  isFuture: PropTypes.bool,
  propFilters: PropTypes.object,
  roomIds: PropTypes.object,
  spaceMetaTypes: PropTypes.object,
  spaces: PropTypes.object,
  availableAssets: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  spaceIds: PropTypes.object,
  selectedDate: PropTypes.instanceOf(Date),
  futureDesks: PropTypes.array,
  futureRooms: PropTypes.array,
  currentFloorId: PropTypes.number,
}

const mapStateToProps = (state) => ({
  futureDesks: getFutureDesks(state),
  futureRooms: getFutureRooms(state),
  deskIds: getActiveDeskIds(state),
  roomIds: getActiveRoomIds(state),
  spaces: getSpaces(state),
  selectedDate: getDateFilter(state),
  durationFilter: getDurationFilter(state),
  availabilityFilter: getAvailabilityFilter(state),
  numSeatFilter: getNumSeatsFilter(state),
  bookableFilter: getBookableFilter(state),
  propFilters: getPropFilters(state),
  spaceIds: getSpaceResults(state),
  userResults: getUserResults(state),
  activeSpaceId: getActiveSpaceId(state),
  activeIndex: getActiveByIndex(state),
  activeDataSet: getActiveDataset(state),
  spaceMetaTypes: getMetaTypes(state),
  isFuture: getIsFuture(state),
  currentFloorId: getCurrentFloorId(state),
})

export default connect(mapStateToProps, { setActiveByIndex })(SpaceResults)
