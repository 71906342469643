import { takeEvery, select, all, call, put, take } from 'redux-saga/effects'
import { differenceInMinutes } from 'date-fns'
import Alert from 'react-s-alert'

import * as argus from 'api/argus'
import * as colleagues from 'api/colleagues'
import { getActiveSpaceId } from 'containers/quickView/selectors'
import {
  FETCH_PROFILE_PICTURE_REQUEST,
  RESULT_ASSET_CLICK,
  GET_SELECTED_COLLEAGUES,
  SELECT_A_COLLEAGUE,
  DELETE_SELECTED_COLLEAGUE,
} from 'containers/searchPanel/results/constants'
import { SEARCH_CLEAR } from 'containers/searchPanel/search/constants'
import {
  SET_PROP_FILTER,
  SET_BOOKABLE_FILTER,
  SET_AVAILABILITY_FILTER,
  SET_DURATION_FILTER,
  SET_NUM_SEATS_FILTER,
} from 'containers/searchPanel/filter/constants'
import {
  fetchProfilePictureSuccess,
  fetchProfilePictureFailure,
  getSelectedColleaguesSuccess,
  getSelectedColleagues,
} from 'containers/searchPanel/results/actions'
import { getAssetCalendar } from 'containers/quickView/spaceModal/claimFlow/actions'
import {
  authenticateFailure,
  startLocate,
  startFilter,
} from 'containers/app/actions'
import {
  FETCH_FUTURE_VIEW_SUCCESS,
  FILTER_SUCCESS,
} from 'containers/app/constants'
import {
  getSpaces,
  getActiveDeskIds,
  getActiveRoomIds,
  getMetaTypes,
  getFutureDesks,
  getUserIdsOnFloors,
  getCurrentFloorId,
  getFutureRooms,
} from 'containers/app/selectors'
import {
  getPropFilters,
  getActiveDataset,
  getBookableFilter,
  getDurationFilter,
  getAvailabilityFilter,
  getNumSeatsFilter,
  getIsFuture,
} from 'containers/searchPanel/filter/selectors'
import { getSearchQuery } from 'containers/searchPanel/search/selectors'

function* fetchProfilePicture({ userId }) {
  try {
    const response = yield call(
      argus.fetchBlob,
      `/secure/user/photo/?id=${userId}`,
    )
    const objectUrl = URL.createObjectURL(response)
    yield put(fetchProfilePictureSuccess(objectUrl, userId))
  } catch ({ error, response }) {
    yield put(fetchProfilePictureFailure(userId))
  }
}
function* fetchHiddenIds() {
  const activeDataSet = yield select(getActiveDataset)
  const deskIds = yield select(getActiveDeskIds)
  const roomIds = yield select(getActiveRoomIds)

  const ids = []
  if ((activeDataSet === 'rooms' || !activeDataSet) && roomIds) {
    ids.push(...roomIds)
  }
  if ((activeDataSet === 'desks' || !activeDataSet) && deskIds) {
    ids.push(...deskIds)
  }
  return ids
}

function* filterSpaces() {
  const ids = yield call(fetchIds)
  const bookableIds = yield call(applyBookableFilter, ids)
  const availableIds = yield call(applyAvailabilityFilter, bookableIds)
  const spaceIdsWithEnoughCapacity = yield call(
    applyNumSeatsFilter,
    availableIds,
  )
  const spaceIdsWithEnoughDuration = yield call(
    applyDurationFilter,
    spaceIdsWithEnoughCapacity,
  )
  const filteredIds = yield call(applyPropFilter, spaceIdsWithEnoughDuration)

  const hideIds = yield call(fetchHiddenIds)
  const currentFloorId = yield select(getCurrentFloorId)
  const allUserIds = yield select(getUserIdsOnFloors)
  const currentFloorUsersIds = allUserIds.get(`${currentFloorId}`)
  const isFuture = yield select(getIsFuture)
  const query = yield select(getSearchQuery)

  const hideUserIds = []
  if (isFuture && currentFloorUsersIds) {
    for (const user of currentFloorUsersIds) {
      hideUserIds.push(user)
    }
  }

  if (!query) {
    yield put(startFilter('update', filteredIds, hideIds, hideUserIds))
    yield take(FILTER_SUCCESS)
  }
}
function* fetchIds() {
  const isFuture = yield select(getIsFuture)
  const futureDesks = yield select(getFutureDesks)
  const futureRooms = yield select(getFutureRooms)
  const activeDataSet = yield select(getActiveDataset)
  const deskIds = yield select(getActiveDeskIds)
  const roomIds = yield select(getActiveRoomIds)

  const uniqueIds = new Set()

  function addAssetsToSet(assets) {
    if (!assets) {
      return
    }
    assets.forEach((asset) => uniqueIds.add(asset.id || asset))
    return uniqueIds
  }

  const shouldShowDesks = activeDataSet === 'desks' || !activeDataSet
  const shouldShowRooms = activeDataSet === 'rooms' || !activeDataSet

  if (isFuture && !activeDataSet) {
    ;[futureDesks, futureRooms, deskIds, roomIds].forEach(addAssetsToSet)
  } else {
    if (shouldShowDesks) {
      addAssetsToSet(deskIds)
    }
    if (shouldShowRooms) {
      addAssetsToSet(roomIds)
    }
  }
  return Array.from(uniqueIds)
}

function* applyPropFilter(arr) {
  const propFilters = yield select(getPropFilters)
  const spaces = yield select(getSpaces)

  const filters = propFilters.filter((filter) => filter)

  const filteredIds = arr.filter((id) => {
    const space = spaces.get(`${id}`)
    if (!space) return false

    return filters.keySeq().reduce((acc, filter) => {
      const spaceProps = space.getIn(['info', 'props'])
      if (!spaceProps) return null
      const result = spaceProps.find((prop) => prop.get('name') === filter)
      return !result ? false : acc
    }, true)
  })
  return filteredIds
}

function* applyDurationFilter(arr) {
  const spaces = yield select(getSpaces)
  const isFuture = yield select(getIsFuture)
  const durationFilter = yield select(getDurationFilter)

  if (!isFuture && durationFilter && durationFilter > 0) {
    return arr.filter((id) => {
      const space = spaces.get(`${id}`)
      if (!space) return false
      //filter out meetings that have already finished
      const futureMeetings = space
        .get('meetings')
        .valueSeq()
        .filter((meeting) => {
          return Date.now() < meeting.get('end')
        })
      const firstMeeting = futureMeetings.first()

      //check if there is enough room to schedule the desired meeting duration
      if (firstMeeting) {
        const diff = differenceInMinutes(firstMeeting.get('start'), Date.now())
        if (diff < 0) return false // check for ongoing meeting (will give negative diff value)
        return durationFilter < diff //check if there is enough time for the searched duration before the next meeting
      }
      return true
    })
  } else {
    return arr
  }
}

function* applyAvailabilityFilter(arr) {
  const availabilityFilter = yield select(getAvailabilityFilter)
  const futureDesks = yield select(getFutureDesks)
  const futureRooms = yield select(getFutureRooms)
  const spaces = yield select(getSpaces)
  const spaceMetaTypes = yield select(getMetaTypes)
  const isFuture = yield select(getIsFuture)
  const durationFilter = yield select(getDurationFilter)

  if (!availabilityFilter && durationFilter <= 0) return arr

  return arr.filter((id) => {
    const space = spaces.get(`${id}`)
    if (!space) return false
    const spaceTypeId = space.get('typeId')
    const type = spaceMetaTypes.get(`${spaceTypeId}`)

    return isFuture
      ? futureDesks?.find((desk) => desk.id === id)?.state === 'FREE' ||
          futureRooms?.find((room) => room.id === id)?.state === 'FREE'
      : space.getIn(['status', 'state']) === 'FREE' &&
          (type.getIn(['space', 'mode']) === 'BOOK' ||
            type.getIn(['space', 'mode']) === 'CLAIM' ||
            type.getIn(['space', 'mode']) === 'TAKE')
  })
}

function* applyBookableFilter(arr) {
  const spaceMetaTypes = yield select(getMetaTypes)
  const spaces = yield select(getSpaces)
  const bookableFilter = yield select(getBookableFilter)

  if (!bookableFilter) return arr

  return arr.filter((id) => {
    const space = spaces.get(`${id}`)
    if (!space) return false
    const spaceTypeId = space.get('typeId')
    const type = spaceMetaTypes.get(`${spaceTypeId}`)
    return type.getIn(['space', 'mode']) === 'BOOK'
  })
}

function* applyNumSeatsFilter(arr) {
  const numSeatFilter = yield select(getNumSeatsFilter)
  const spaces = yield select(getSpaces)
  const isFuture = yield select(getIsFuture)

  if (numSeatFilter && numSeatFilter > 0) {
    return arr.filter((id) => {
      const space = spaces.get(`${id}`)
      if (!space) return false
      return space.getIn(['info', 'cap']) >= numSeatFilter
    })
  } else {
    return arr
  }
}

function* assetClick({ space }) {
  const activeId = yield select(getActiveSpaceId)
  yield put(startFilter('update', [space.id]))
  yield put(getAssetCalendar(space.id))
  yield take(FILTER_SUCCESS)
  if (space.id !== activeId) {
    yield put(startLocate(space.floorId, 'space', space.id))
  }
}

function* watchAssetClick() {
  yield takeEvery(RESULT_ASSET_CLICK, assetClick)
}
function* watchFilterSpaces() {
  yield takeEvery(
    [
      SET_PROP_FILTER,
      SET_BOOKABLE_FILTER,
      SET_AVAILABILITY_FILTER,
      SET_DURATION_FILTER,
      SET_NUM_SEATS_FILTER,
      FETCH_FUTURE_VIEW_SUCCESS,
      SEARCH_CLEAR,
    ],
    filterSpaces,
  )
}

function* getAllSelectedColleagues() {
  try {
    const response = yield call(colleagues.getSelectedColleagues)
    yield put(getSelectedColleaguesSuccess(response))
  } catch ({ error, response }) {
    if (response.status === 401) {
      yield put(authenticateFailure())
    } else {
      Alert.error(`ERROR: ${error.message}`)
    }
  }
}

function* selectFavorite({ userId }) {
  try {
    const response = yield call(colleagues.addColleagueToSelected, userId)
    yield put(getSelectedColleagues())
  } catch ({ error, response }) {
    if (response.status === 401) {
      yield put(authenticateFailure())
    } else {
      Alert.error(`ERROR: ${error.message}`)
    }
  }
}

function* deleteUserFromSelected({ userId }) {
  try {
    const response = yield call(colleagues.removeColleagueFromSelected, userId)
    yield put(getSelectedColleagues())
  } catch ({ error, response }) {
    if (response.status === 401) {
      yield put(authenticateFailure())
    } else {
      Alert.error(`ERROR: ${error.message}`)
    }
  }
}

function* watchProfilePictureRequest() {
  yield takeEvery(FETCH_PROFILE_PICTURE_REQUEST, fetchProfilePicture)
}
function* watchGetSelectedColleagues() {
  yield takeEvery(GET_SELECTED_COLLEAGUES, getAllSelectedColleagues)
}

function* watchSelectFavorites() {
  yield takeEvery(SELECT_A_COLLEAGUE, selectFavorite)
}

function* watchDeleteUserFromSelected() {
  yield takeEvery(DELETE_SELECTED_COLLEAGUE, deleteUserFromSelected)
}

function* results() {
  yield all([
    watchProfilePictureRequest(),
    watchAssetClick(),
    watchFilterSpaces(),
    watchGetSelectedColleagues(),
    watchSelectFavorites(),
    watchDeleteUserFromSelected(),
  ])
}
export default results
