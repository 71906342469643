import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import { getUnixTime } from 'date-fns'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import styled from 'styled-components'

import SpaceBookingsList from 'containers/quickView/modal/SpaceBookingsList'
import { BookingInnerDivider } from 'containers/mainPanel/bookingsList/BookingDividers'
import SpaceBooking from 'containers/quickView/spaceModal/SpaceBooking'
import QuickViewBackdrop from 'containers/quickView/QuickViewBackdrop'
import Modal from 'containers/quickView/Modal'
import {
  clearQuickView,
  deleteBookingMessageToShow,
} from 'containers/quickView/actions'
import TimePickerArea from 'containers/quickView/TimePickerArea'
import {
  getSpaces,
  getMetaTypes,
  getFutureDeskbookingEnabled,
  getSelfCalendarItems,
  getFutureDesks,
  getFutureRooms,
  getDisableTimeSelection,
} from 'containers/app/selectors'
import toJS from 'components/toJS'
import ModalStatus from 'containers/quickView/modal/ModalStatus'
import SpaceInfo from 'containers/quickView/spaceModal/SpaceInfo'
import ClaimableActions from 'containers/quickView/spaceModal/ClaimableActions'
import BookingFlow from 'containers/quickView/spaceModal/BookingFlow'
import SpaceProps from 'containers/quickView/spaceModal/SpaceProps'
import { deselect } from 'api/unity'
import {
  getStatus,
  getMeetingSlots,
} from 'containers/quickView/spaceModal/bookingFlow/selectors'
import ModalTitle from 'containers/quickView/modal/ModalTitle'
import ModalRow from 'containers/quickView/modal/ModalRow'
import ModalInfoBox from 'containers/quickView/ModalInfoBox'
import {
  getDateFilter,
  getIsFuture,
} from 'containers/searchPanel/filter/selectors'
import { checkSlotInterval } from 'utils/utilsFunctions'
import {
  getAssetCalendar,
  closeClaimUI,
  setDeleteOnBehalfPermission,
} from 'containers/quickView/spaceModal/claimFlow/actions'
import {
  getEventsPerAsset,
  getIsFetching,
} from 'containers/quickView/spaceModal/claimFlow/selectors'
import { getBookingMessage } from 'containers/quickView/selectors'
import WrapperDrawerController from 'containers/quickView/modal/WrapperDrawerController'
import { sendConfirmation } from 'containers/mainPanel/bookedResources/actions'
import StaticPopover from 'components/StaticPopover'
import { ASSET_STATUSES, MODE_MAP } from 'utils/appVars'

const DailyBookingsHeader = styled.span`
  padding: 35px 0 0 25px;
  font-size: 18px;
  font-weight: 500;
  position: relative;
  color: ${(props) => props.theme.darkText};
  display: block;
`
const StyledModaRow = styled(ModalRow)`
  padding-right: 5px;
  padding-left: 8px;
`
const StyledModaRowBookings = styled(ModalRow)`
  border: none !important;
  padding-left: 9px;
`
const StyledSpaceInfo = styled(SpaceInfo)`
  padding-top: 35px !important;
`
const InfoBoxWrapper = styled.div`
  position: absolute;
  bottom: 100px;
  top: initial;
  left: 5px;
  z-index: 1;
`

class SpaceModal extends Component {
  state = {
    showPopup: false,
    popupData: {},
  }
  timerID = null
  componentDidMount() {
    const { space, getAssetCalendar } = this.props
    getAssetCalendar(space.id)
  }
  componentDidUpdate(prevProps) {
    const { space, futureSpace, getAssetCalendar } = this.props
    const hasNewFutureEvent = this.checkForNewEvent(
      prevProps.futureSpace,
      futureSpace,
    )
    const hasNewCurrentMeeting = this.checkForNewMeeting(prevProps.space, space)

    if (hasNewCurrentMeeting || hasNewFutureEvent) {
      getAssetCalendar(space.id)
    }

    if (
      prevProps.bookingMessage !== this.props.bookingMessage &&
      this.props.bookingMessage.length > 0
    ) {
      if (this.timerID) {
        clearTimeout(this.timerID)
      }
      this.timerID = setTimeout(() => {
        this.props.deleteBookingMessageToShow()
      }, 4000)
    }
  }

  componentWillUnmount() {
    if (this.timerID) {
      clearTimeout(this.timerID)
    }
  }

  handleClose = () => {
    const { clearQuickView } = this.props
    clearQuickView()
    deselect()
  }

  handleBack = () => {
    const { closeClaimUI } = this.props
    closeClaimUI()
  }
  checkForNewEvent = (prevProps, newProps) => {
    if (prevProps.events && newProps.events) {
      return prevProps.events.length !== newProps.events.length
    }
  }
  checkForNewMeeting = (prevProps, newProps) => {
    if (prevProps.meetings && newProps.meetings) {
      return prevProps.meetings.length !== newProps.meetings.length
    }
  }
  checkIfBooked = () => {
    const { spaceSlots, selectedDate } = this.props
    if (!spaceSlots.bookings) return false
    const isBooked = spaceSlots.bookings.find((booking) =>
      checkSlotInterval(booking, selectedDate),
    )
      ? true
      : false

    return isBooked
  }

  render() {
    const {
      space,
      type,
      selectedDate,
      eventsPerAsset,
      selfCalendar,
      setDeleteOnBehalfPermission,
      deleteBookingMessageToShow,
      bookingMessage,
      isFuture,
      disableTimeSelection,
      isFetching,
      futureSpace,
    } = this.props

    const eventsForCurrentUser = selfCalendar
      ? selfCalendar.filter((item) => item.spaceId === space.id && item.owner)
      : []
    const currentMoment = getUnixTime(new Date()) / 60

    const currentSpaceEvent = eventsPerAsset
      ? eventsPerAsset.find(
          (event) => event.start < currentMoment && event.end > currentMoment,
        )
      : {}

    const deleteEvent = (booking) => {
      const { id: eventId, start, end } = booking
      setDeleteOnBehalfPermission(space.id, eventId, start, end)
      if (!isFetching) {
        this.setState({ showPopup: false })
      }
    }
    const currentUserEvent =
      eventsForCurrentUser && currentSpaceEvent
        ? eventsForCurrentUser.find(
            (event) =>
              event.start === currentSpaceEvent.start &&
              event.end === currentSpaceEvent.end,
          )
        : {}

    const hasRoomBookingPermission = type.space.mode === ASSET_STATUSES.BOOK
    const hasDeskBookingPermission = type.space.mode === ASSET_STATUSES.CLAIM
    const hasBookingPermission =
      hasRoomBookingPermission || hasDeskBookingPermission
    const bookableAssetTypes = [
      ASSET_STATUSES.FREE,
      ASSET_STATUSES.BOOKED,
      ASSET_STATUSES.CLAIMED,
      ASSET_STATUSES.BUSY,
    ]
    const nonBookableAssetTypes = [ASSET_STATUSES.NONE, ASSET_STATUSES.OFFLINE]

    const hasBookings = eventsPerAsset.some((booking) =>
      checkSlotInterval(booking, selectedDate),
    )
    let isBookableAsset = bookableAssetTypes.includes(space.status.state)

    if (isFuture && futureSpace) {
      isBookableAsset = !nonBookableAssetTypes.includes(futureSpace.state)
    }
    const showBookingsList =
      hasBookings || (isBookableAsset && hasBookingPermission)
    const showRoomBookingFlow = hasRoomBookingPermission && isBookableAsset
    const showDeskBookingFlow = hasDeskBookingPermission && isBookableAsset

    const isTimeSelectionEnabled = !disableTimeSelection || showRoomBookingFlow
    const showTimePicker =
      isBookableAsset && hasBookingPermission && isTimeSelectionEnabled

    const sortedAssetEvents = eventsPerAsset
      .filter((booking) => checkSlotInterval(booking, selectedDate))
      .sort((a, b) => a.start - b.start)
    const assetType = MODE_MAP[type.space.mode]

    const getSpaceStatus = () => {
      const { type, space, futureSpace, isFuture } = this.props
      const bookableModes = [ASSET_STATUSES.BOOK, ASSET_STATUSES.CLAIM]
      if (bookableModes.includes(type.space.mode) && isFuture && futureSpace) {
        return futureSpace.state
      }
      if (bookableModes.includes(space.status.state) && !currentSpaceEvent) {
        return ASSET_STATUSES.FREE
      }
      return space.status.state
    }
    return (
      <WrapperDrawerController>
        <QuickViewBackdrop />
        <Modal asset={showBookingsList}>
          <ModalStatus
            status={getSpaceStatus()}
            onClick={this.handleClose}
            onBack={this.handleBack}
            selectedDate={selectedDate}
          />
          <Grid container>
            <Grid item xs={showBookingsList ? 7 : 12}>
              <StyledSpaceInfo
                space={space}
                type={type}
                status={getSpaceStatus()}
              />
              <SpaceProps spaceProps={space.info.props} />
              {type.space.mode === ASSET_STATUSES.TAKE && (
                <Fragment>
                  <ModalRow>
                    <ModalTitle>
                      <Translate id="filter.nonBookable" />
                    </ModalTitle>
                  </ModalRow>
                </Fragment>
              )}
              {showTimePicker && (
                <TimePickerArea
                  key={selectedDate}
                  type={assetType}
                  eventsPerAsset={eventsPerAsset}
                />
              )}
              <InfoBoxWrapper>
                <ModalInfoBox isBookingMessage showMessage={bookingMessage} />
              </InfoBoxWrapper>
              {showRoomBookingFlow && (
                <BookingFlow
                  space={space}
                  event={currentSpaceEvent}
                  owner={currentUserEvent && currentUserEvent.owner}
                  showMessage={bookingMessage}
                />
              )}
              {showDeskBookingFlow && (
                <ClaimableActions
                  space={space}
                  isBooked={this.checkIfBooked()}
                  bookingMessage
                  spaceStatus={getSpaceStatus()}
                />
              )}
            </Grid>
            {showBookingsList && (
              <>
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{ marginRight: '-1px' }}
                />
                <Grid item xs={5}>
                  <DailyBookingsHeader>
                    <Translate id="quickView.form.bookings" />
                  </DailyBookingsHeader>
                  <SpaceBookingsList>
                    {!hasBookings ? (
                      <StyledModaRowBookings>
                        <Translate id="billboard.mainPanel.noBooking" />
                      </StyledModaRowBookings>
                    ) : (
                      sortedAssetEvents.map((event) => (
                        <StyledModaRow key={event.id}>
                          <SpaceBooking
                            deleteEvent={deleteEvent}
                            booking={event}
                            key={event.id}
                            isRoom={hasRoomBookingPermission}
                            space={space}
                            showConfirmationPopup={(popupData) =>
                              this.setState({
                                showPopup: true,
                                popupData: popupData,
                              })
                            }
                          />
                          <BookingInnerDivider />
                        </StyledModaRow>
                      ))
                    )}

                    <StaticPopover
                      closeConfirmationPopup={() =>
                        this.setState({ showPopup: false })
                      }
                      sendConfirmation={(popupData) => deleteEvent(popupData)}
                      popupData={this.state.popupData}
                      showPopup={this.state.showPopup}
                      isFetching={isFetching}
                      deleteReservationMessage={this.state.showPopup}
                    />
                  </SpaceBookingsList>
                </Grid>
              </>
            )}
          </Grid>
        </Modal>
      </WrapperDrawerController>
    )
  }
}

SpaceModal.propTypes = {
  clearQuickView: PropTypes.func,
  space: PropTypes.object,
  type: PropTypes.object,
  spaceSlots: PropTypes.object,
  deskBookingEnabled: PropTypes.bool,
  selectedDate: PropTypes.PropTypes.instanceOf(Date),
  getAssetCalendar: PropTypes.func,
  eventsPerAsset: PropTypes.array,
  selfCalendar: PropTypes.array,
  closeClaimUI: PropTypes.func,
  setDeleteOnBehalfPermission: PropTypes.func,
  isFuture: PropTypes.bool,
  futureSpace: PropTypes.object,
  deleteBookingMessageToShow: PropTypes.func,
  bookingMessage: PropTypes.string,
  disableTimeSelection: PropTypes.bool,
  isFetching: PropTypes.bool,
}

const mapStateToProps = (state, { id }) => {
  const space = getSpaces(state).get(`${id}`)
  const typeId = space.get('typeId')
  const type = getMetaTypes(state).get(`${typeId}`)

  const futureDesks = getFutureDesks(state)
  const futureRooms = getFutureRooms(state)
  const futureSpaces = [...(futureDesks || []), ...(futureRooms || [])]
  const futureSpace = futureSpaces.find((space) => space.id === id) || {}

  return {
    space,
    type,
    spaceSlots: getMeetingSlots(state),
    selectedDate: getDateFilter(state),
    deskBookingEnabled: getFutureDeskbookingEnabled(state),
    eventsPerAsset: getEventsPerAsset(state),
    selfCalendar: getSelfCalendarItems(state),
    futureSpace,
    isFuture: getIsFuture(state),
    bookingMessage: getBookingMessage(state),
    disableTimeSelection: getDisableTimeSelection(state),
    isFetching: getIsFetching(state),
  }
}

export default connect(mapStateToProps, {
  clearQuickView,
  getAssetCalendar,
  closeClaimUI,
  setDeleteOnBehalfPermission,
  deleteBookingMessageToShow,
  sendConfirmation,
})(toJS(SpaceModal))
